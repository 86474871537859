.container-class {
  font-size: 18px !important;
  line-height: 25px !important;
}
.title-style {
  margin-top: 20px;
  margin-bottom: 16px;
  margin-left: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.title-class {
  color: #172c45;
  font-size: 24px;
}

.close-button-class {
  color: #172c45;
}

.close-button-class:hover {
  color: #ff5656;
}

.icon-class {
  border-width: 0px;
  color: #ffffff !important;
  background-color: #fa5455;
}

.popup-class {
  border-radius: 0;
}

.btn-success {
  background-color: #18d399 !important;
}

.btn-danger {
  background-color: #ff5656;
}

.success-icon-class span {
  background-color: #18d399 !important;
}

.success-icon-class .swal2-success-ring {
  border-color: #18d399 !important;
}
